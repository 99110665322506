<template>
        <div class="d-flex flex-row plCounterDiv">

           <div class="plHomeOdometer useFont">{{ counter }}</div> 
        
            <div>  
               <div class="plHometitle useFont fontSize16">{{title}}</div>
               <div class="plHomeDate  useFont fontSize16">Last Updated: {{lastUpdated}}</div>
           </div>  
  
        </div>
</template>


<script>
       
    import { store }      from '@/store/store'

    export default {
      name    : 'plCounter',
      props   : ["name", "title"],
      data () {
        return {  
            store,
            counter : 0,
        }
      },

      computed: {
          lastUpdated() {
               return new Date().toLocaleDateString('en-GB', { timeZone: 'UTC'}); 
          }
      },
      watch: {},

      mounted() {
          
          if ( this.name == 'shortlist') {
              this.counter = ( this.store.shortList ) ? this.store.shortList.length : 0;
          } else if ( this.name == 'selected') {
              this.counter =  ( this.store.selected )   ? this.store.selected.length : 0 ;
          } else {
              this.odoMeterCounter();
          }

      },

      methods : {
               
                    odoMeterCounter : async function() {
                
                        let candidateCount =  ( this.store.candidates.count )  ? this.store.candidates.count : 0;
                        if (candidateCount == 0) { 
                             setTimeout( () => { this.odoMeterCounter() }, 100 );
                             return;
                        }
                        
                        if ( this.counter < candidateCount ){ 
                             this.counter += 30;
                             setTimeout( () => { this.odoMeterCounter() }, 20 );
                             return;
                        } else {
                             this.counter = candidateCount;
                        }
 
                    }
      }

    }

</script>

<style scoped>

 .plCounterDiv{
  background-color: black;
  color:#FFFFFF;
  gap: 0px 0px;
  text-align: center;
  padding: 10px;
  justify-content: space-evenly !important;
}

.plHomeOdometer{
  color         : rgb(250, 250, 250);
  font-family   : "Helvetica Neue", sans-serif;
  font-size     : 24px;
  font-weight   : 400;
  border        : 2px dashed white;
  border-radius : 10px;
  padding       : 5px;
  width         : 55px !important;
}

.plHomeDate{
  color: rgb(250, 250, 250) !important;
}

.plHomeTitle{
  color: rgb(250, 250, 250) !important;
}

</style>