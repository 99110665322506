<template>
 
          <v-bottom-navigation class="useFont" grow horizontal app bottom fixed v-model="store.bottomNav">
                               

                                 <v-btn :to="{name: 'home' }" value="home">
                                    <v-icon class="pb-2" color="blue">mdi-home</v-icon>
                                 </v-btn>
                                              
    
                                  <v-btn 
                                      v-if      = "!disableShortListLink"
                                      :disabled = "disableShortListLink" 
                                      :to       = "{name: 'shortlist'}"
                                      value     = "shortlist"
                                  >
                                   <v-icon class="pb-2" color="blue">mdi-filter</v-icon>
       `                          </v-btn>


                                  <v-btn
                                     v-if     = "!disableEnquiryLink" 
                                    :disabled = "disableEnquiryLink" 
                                    :to       = "{name: 'enquiry'}"
                                    value     = "enquiry"
                                  >
                                   <v-icon class="pb-2" color="blue">mdi-email</v-icon>
                                  </v-btn>

                                 <v-btn   @click.stop="store.showAboutDialog = true">
                                     <v-icon class="pb-2" color="blue" >mdi-information</v-icon>
                                 </v-btn>

          </v-bottom-navigation>

</template>

<script>
       
    import { store }      from '@/store/store'
    export default {
      name    : 'plFooter',
      data () {
        return {  
            store,
            bottomNav: ''
        }
      },
      methods : {},
      
       computed : {
      
          disableShortListLink() {
             return ( store.shortList.length == 0 ) ? true : false;
          },
      
          disableEnquiryLink() {
            return ( store.selected.length == 0 ) ? true : false;
          }

      }

    }

</script>

<style scoped>
 .plFooterDiv{
     background: linear-gradient(#9867FF,#9867FF);
 }

</style>