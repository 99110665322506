<template>

  <div>
    <plCounter             title="Shortlisted Candidates" name="shortlist"/>
    <plShortlist            />
    <plFabButton           fabView="shortlist" />

  </div>

</template>

<script>

import { store }                from '../store/store'
import plCounter                from '@/components/Header/plCounter.vue';
import plFabButton              from '@/components/FabButton/plFabButton.vue';
import plShortlist              from '@/components/plShortlist.vue';

export default {  

name        : 'Shortlist',
components  : { plCounter, plShortlist, plFabButton},
computed    : {},
methods     : {},


data() {
  
  return {
      store,
   };
},

}
</script>