import { render, staticRenderFns } from "./aboutDialog.vue?vue&type=template&id=3693b0d7&scoped=true"
import script from "./aboutDialog.vue?vue&type=script&lang=js"
export * from "./aboutDialog.vue?vue&type=script&lang=js"
import style0 from "./aboutDialog.vue?vue&type=style&index=0&id=3693b0d7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3693b0d7",
  null
  
)

export default component.exports